//All code for fontend only
var feLib = "";

(function () {
    // function trigger_contact_us_modal() {
    //     $(".c-contact-us-modal").modal({
    //         fadeDuration: 100,
    //         showClose: false
    //     });
    // }

    // feLib = {
    //     trigger_contact_us_modal: trigger_contact_us_modal
    // }
})();

$(document).ready(function () {
    // function msieversion() {
    //     var ua = window.navigator.userAgent;
    //     var msie = ua.indexOf("MSIE ");

    //     if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
    //     {
    //         $('img').bicubicImgInterpolation({
    //             crossOrigin: 'anonymous' //only for demo purpose (external img source is requested) otherwise browser security error is triggered
    //         });
    //     }
    //     else  // If another browser, return 0
    //     {
    //         // alert('otherbrowser');
    //     }

    //     return false;
    // }

    // msieversion();
})