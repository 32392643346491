app.component('customSectionText', {
    // This is the HTML
    template:
        `<span class="{{props.customClass}}" ng-style="sectionText.customStyle" ng-init="initSectionText()">{{props.text}}</span>`,
    // This is for props
    bindings: {
        text: "@",
        customClass: "@",
        fontSize: "@"
    },
    // This is for the controller
    controller: sectionTextController
})
//Please try to write in ES 9 version if possible
//Declaring main module functions in 'function' is easier to read than lambda expressions
//Try not to use too much nested loop or nested if/else statement, chances are the code logic have issues
//Try to revise the code logic. For else if statement, use switch case statement instead
//Endpoint is at header script
sectionTextController.$inject = ['$scope'];
function sectionTextController($scope) {
    // This is the state
    $scope.sectionText = {
        customStyle: {}
    };

    $scope.initSectionText = () => {
        $scope.props = $scope.$ctrl;

        $scope.sectionText.customStyle = {
            "font-size": $scope.props.fontSize
        };
    }
}