var app = angular.module(projectName, ['ServiceManager', 'ngSanitize', 'ngPatternRestrict']);

app.run(globalFunction);

//Please try to write in ES 9 version if possible
//Declaring main module functions in 'function' is easier to read than lambda expressions
//Try not to use too much nested loop or nested if/else statement, chances are the code logic have issues
//Try to revise the code logic. For else if statement, use switch case statement instead
//Endpoint is at header script
globalFunction.$inject = ['HTTPService', 'NotificationService', 'LoadingService', 'AppConstant', '$rootScope'];
function globalFunction(HTTPService, NotificationService, LoadingService, AppConstant, $rootScope) { }

app.controller("ShowcaseController", showcaseController);

showcaseController.$inject = ['$scope'];
function showcaseController($scope) {
}

app.controller("FormPageController", formPageController);

formPageController.$inject = ['$scope', '$sce', 'QueryParamService', 'HTTPService', '$q', '$timeout'];
function formPageController($scope, $sce, QueryParamService, HTTPService, $q, $timeout) {
    $scope.formState = {
        firstName: "",
        lastName: "",
        email: "",
        country: "",
        industry: "",
        company: "",
        role: "",
        subscription: ""
    }

    $scope.countryList = [];
    $scope.industryList = [];

    $scope.initFormPage = () => {
        HTTPService.getURLEncoded("assets/data/contacts.json").then(contacts => {
            $scope.countryList = contacts;
        }).catch(() => {
            alert("Unable to get the list of country");
        });
        HTTPService.getURLEncoded("assets/data/industry.json").then(industries => {
            $scope.industryList = industries;
        }).catch(() => {
            alert("Unable to get the list of Industry");
        });
    }
    $scope.submitForm = () => {
        sessionStorage.setItem("personalInfo", JSON.stringify($scope.formState));

        window.location.href = "/showcase/showcase.html";
    }

    $scope.openTNC = () => {
        $timeout(() => {
            $("body").addClass("lock-overflow");
            $(".custom-modal").addClass("show");
        }, 300);
    }

    $scope.closeTNC = () => {
        $("body").removeClass("lock-overflow");
        $(".custom-modal").removeClass("show");
    }
}

app.controller("BusinessResiliencePageController", businessResiliencePageController);

businessResiliencePageController.$inject = ['$scope', '$sce', 'QueryParamService', 'HTTPService', '$q', '$timeout'];
function businessResiliencePageController($scope, $sce, QueryParamService, HTTPService, $q, $timeout) {
    // This is the state
    $scope.roadMap = {};
    $scope.divisionTools = [];

    $scope.initBusinessResiliencePageController = () => {
        // console.log("Init page BusinessResiliencePageController");
        loadDivisionSetting();
    }

    let loadDivisionSetting = () => {
        var defer = $q.defer();

        HTTPService.getURLEncoded("assets/data/division.json").then((division) => {
            $scope.divisionTools = angular.copy(division);
            $scope.divisionTools.forEach(d => {
                d.tools.forEach(t => {
                    // t.labelHTML = $sce.trustAsHtml(t.labelHTML);
                    t.labelHTML = t.labelHTML.replace(/<[^>]*>/g, ',');
                    t.labelHTML = t.labelHTML.substring(1, t.labelHTML.length - 1);
                    t.labelHTML = t.labelHTML.split(",,");
                });
            });
            defer.resolve(true);
        }).catch(() => {
            alert("Not able to load setting");
            defer.reject();
        });

        return defer.promise;
    }

    $scope.pointTrustHtml = function(text) {
        return $sce.trustAsHtml(text);
    }

    $scope.toolsClicked = (tool, heatClick) => {
        // console.log(tool);
        if (tool.heatClick == heatClick) {
            tool.heatClick = 0;
        }
        else {
            tool.heatClick = heatClick;
        }
        $(".heatmap-choices").removeClass("show");
    }

    $scope.triggerChoicePopup = (elem, tool) => {
        if (tool.heatClick > 0) {
            tool.heatClick = 0;
        }
        else {
            if ($(elem).next(".heatmap-choices").hasClass("show")) {
                $(elem).next(".heatmap-choices").removeClass("show");
            }
            else {
                $(".heatmap-choices").removeClass("show");
                $(elem).next(".heatmap-choices").addClass("show");
            }
        }
    }

    $scope.getClickedTools = (id) => {
        let foundTools = [];
        let foundDivision = $scope.divisionTools.find(x => x.id == id);

        if (foundDivision) {
            let filteredTools = foundDivision.tools.filter(d => d.heatClick > 0);

            if (filteredTools && filteredTools.length > 0) {
                filteredTools.sort(sortToolsByHeatClick);
                foundTools = filteredTools;
            }
        }

        return foundTools;
    }

    let sortToolsByHeatClick = (a, b) => {
        if (a.heatClick > b.heatClick) return -1;
        if (b.heatClick > a.heatClick) return 1;

        return 0;
    }

    let sortToolsBySequence = (a, b) => {
        if (a.sequence > b.sequence) return 1;
        if (b.sequence > a.sequence) return -1;

        return 0;
    }

    $scope.getHueColor = (heatClick) => {
        let hueColor;
        switch (heatClick) {
            case 1:
                hueColor = "cls-6";
                break;
            case 2:
                hueColor = "cls-5";
                break;
            case 3:
                hueColor = "cls-4";
                break;
            default:
                break;
        }
        return hueColor;
    }

    let reportObj = []
    $scope.generateReportObject = () => {
        let generatedReportObject = filterClickedTools();

        if (generatedReportObject && generatedReportObject.length > 0) {
            reportObj = removeExcessData(generatedReportObject);

            sessionStorage.setItem("reportObj", JSON.stringify(reportObj));
            window.location.href = "/roadmap/roadmap.html";
        }
        else {
            alert("Please select at least 1 option");
        }
    }

    let filterClickedTools = () => {
        let reducedData = [];
        $scope.divisionTools.forEach(division => {
            var filteredTools = division.tools.filter(t => t.heatClick > 0);

            if (filteredTools && filteredTools.length > 0) {
                let filteredDivision = angular.copy(division);

                filteredDivision.tools = filteredTools;

                filteredDivision.tools.sort(sortToolsByHeatClick);

                reducedData.push(filteredDivision);
            }
        });

        return reducedData;
    }

    // Gantt Chart 
    $scope.hideFinalizeButton = false;
    $scope.initRoadMap = () => {
        loadDivisionSetting().then(() => {
            let q = QueryParamService.getQueryParamByKey("q");

            getPersonalInfo(q).then((resp) => {
                let reportObj = resp.reportObj;
                let personalInfo = resp.personalInfo;
                $scope.roadMap.companyName = personalInfo.company;

                prefillWheel(reportObj);
                // console.log($scope.divisionTools);
                refreshGanttChart(reportObj);

                if (q) {
                    $scope.hideFinalizeButton = true;
                }
            });
        });

        //Init go to top button
        $(".up-arrow-wrapper .clickable").on("click", () => {
            $('html,body').animate({
                scrollTop: $(".gantt-chart").offset().top - 20
            }, 400);
        });

        $(window).scroll(function () {
            if ($(this).scrollTop() > 0) {
                $(".up-arrow-wrapper").addClass("show");
            } else {
                $(".up-arrow-wrapper").removeClass("show");
            }
        });
        //Init go to top button
    }

    let getPersonalInfo = (q) => {
        var defer = $q.defer();

        if (q) {
            HTTPService.postURLEncoded("assets/services/get_report.php", { "file_name": q }).then((resp) => {
                if (resp.status == "1") {
                    let response = {
                        reportObj: resp.response.reportObj,
                        personalInfo: resp.response.personalInfo
                    }
                    defer.resolve(response);
                }
                else {
                    alert("Error when getting report");
                    defer.reject();
                }
            }).catch((err) => {
                console.log(err);
                defer.reject();
            })
        }
        else {
            let response = {
                reportObj: JSON.parse(sessionStorage.getItem("reportObj")),
                personalInfo: JSON.parse(sessionStorage.getItem("personalInfo"))
            }
            defer.resolve(response);
        }

        return defer.promise;
    }

    let prefillWheel = (reportObj) => {
        console.log(reportObj);
        reportObj.forEach(r => {
            let foundDivision = $scope.divisionTools.find(d => d.id == r.id);

            if (foundDivision) {
                r.tools.forEach(rt => {
                    let foundTool = foundDivision.tools.find(t => t.toolsId == rt.toolsId);

                    if (foundTool) {
                        foundTool.heatClick = rt.heatClick;
                    }
                });
            }
        })
        // angular.merge($scope.divisionTools, reportObj);
    }

    $scope.ganttChartObj = [];
    let refreshGanttChart = (obj) => {
        obj = getExcessData(obj);
        // obj = segregateBasedOnHeatClick(obj);
        obj = segregateBasedOnSequence(obj);
        $scope.ganttChartObj = obj;
    }

    let segregateBasedOnHeatClick = (obj) => {
        obj.forEach(division => {
            let segregationLevel = [];
            for (let i = 3; i > 0; i--) {
                let filtered = division.tools.filter(tool => tool.heatClick == i);

                if (filtered && filtered.length > 0) segregationLevel.push(filtered);
            }

            for (let i = 0; i < segregationLevel.length; i++) {
                let segregatedData = segregationLevel[i];

                if (i > 0 && segregatedData.length > segregationLevel[i - 1].length) {
                    segregationLevel[i - 1].push({});
                }

                // if(filtered && filtered.length > 0) segregationLevel.push(filtered);
            }

            division.segregated = segregationLevel;
        });

        return obj;
    }

    let segregateBasedOnSequence = (obj) => {

        obj.forEach(division => {
            let segregation = [];

            division.tools.sort(sortToolsBySequence);

            // console.log(division.tools[division.tools.length-1]);
            let maxLength = division.tools[division.tools.length - 1].sequence;
            for (let i = 0; i < maxLength; i++) {
                segregation.push({});
            }

            division.tools.forEach(t => {
                segregation[t.sequence - 1] = t;
            })

            division.segregated = segregation;
        });

        return obj;
    }

    let getExcessData = (reportObj) => {
        reportObj.forEach(r => {
            var division = $scope.divisionTools.find(div => div.id == r.id);
            r.divisionLabel = division.divisionLabel;
            r.tools.forEach(t => {
                var foundTool = division.tools.find(tool => tool.toolsId == t.toolsId);
                t.toolsLabel = foundTool.toolsLabel;
                t.labelHTML = t.labelHTML;
            });
        });
        return reportObj;
    }

    let removeExcessData = (reportObj) => {
        reportObj.forEach(r => {
            delete r.$$hashKey;
            delete r.divisionLabel;
            delete r.segregated;
            r.tools.forEach(t => {
                // delete t.toolsLabel;
                delete t.labelHTML;
                delete t.$$hashKey;
                delete t.active;
                delete t.methodFlow;
                delete t.methodologies;
            });
        })

        return reportObj;
    }
    $scope.isGanttChartHorizontallyScrollable = () => {
        let elem = $(".gantt-chart .scrollable")[0];

        let horizontallyScrollable = elem.scrollHeight > elem.clientHeight;
        
        console.log(horizontallyScrollable);
        return horizontallyScrollable;
    }

    $scope.activeToolKit;
    $scope.selectToolKit = (tool) => {
        //Remove currently active toolkit first
        $scope.activeToolKit = undefined;
        $scope.ganttChartObj.forEach(d => {
            let foundTools = d.tools.find(t => t.active);

            if (foundTools) {
                foundTools.active = false;
            }
        })
        if (tool && tool.toolsId) {
            if (!tool.active) {
                tool.active = true;
                $scope.divisionTools.forEach(d => {
                    let foundTools = d.tools.find(t => t.toolsId == tool.toolsId);

                    if (foundTools && foundTools.methodFlow) {
                        $scope.activeToolKit = angular.copy(foundTools);
                        $scope.activeToolKit.divisionLabel = d.divisionLabel;
                    }
                })
                console.log($scope.activeToolKit)
            }
            // else {
            //     tool.active = false;
            //     $scope.activeToolkit = undefined;
            // }
        }

        $timeout(function () {
            $('html,body').animate({
                scrollTop: $(".toolkit").offset().top
            }, 400);
        });
    }

    $scope.activeToolkit;
    $scope.openImage = (toolKit, otherToolKits) => {
        if (toolKit.toolKitImage) {
            otherToolKits.forEach(otk => {
                otk.active = false;
            });
            toolKit.active = true;
            $scope.activeToolkit = toolKit;

            $timeout(() => {
                $("body").addClass("lock-overflow");
                $(".custom-modal").addClass("show");
            }, 300);
        }
    }

    $scope.closeImage = () => {
        $scope.activeToolkit.active = false;
        $("body").removeClass("lock-overflow");
        $(".custom-modal").removeClass("show");
    }

    $scope.saveReport = () => {
        let toSaveGanttChart = angular.copy($scope.ganttChartObj);
        toSaveGanttChart = removeExcessData(toSaveGanttChart);

        //Trigger Save function here
        let toSave = {
            "personalInfo": JSON.parse(sessionStorage.getItem("personalInfo")),
            "reportObj": toSaveGanttChart
        }

        HTTPService.postURLEncoded("assets/services/create_report.php", { "json_file": JSON.stringify(toSave), "email": toSave.personalInfo.email }).then((resp) => {
            if (resp.status == "1") {
                sessionStorage.setItem("reportName", resp.fileName);
                window.location.href = "/thankyou-page/thankyou-page.html?f=" + resp.fileName;
            }
            else {
                alert("Error when creating report");
            }
        }).catch((err) => {
            console.log(err);
        })
    }
}

app.controller("ThankYouPageController", thankYouPageController);

thankYouPageController.$inject = ['$scope', '$sce', 'QueryParamService', 'HTTPService', '$q'];
function thankYouPageController($scope, $sce, QueryParamService, HTTPService, $q) {
    $scope.thankYou = {}
    $scope.countryList = [];

    $scope.initThankYouPage = () => {
        let getUrl = window.location;
        let baseUrl = getUrl.protocol + "//" + getUrl.host;
        $scope.thankYou.downloadURL = baseUrl + "/roadmap/roadmap.html?q=" + sessionStorage.getItem("reportName");
        HTTPService.getURLEncoded("assets/data/contacts.json").then(contacts => {
            $scope.countryList = contacts;

            //Get company name
            let personalInfo = JSON.parse(sessionStorage.getItem("personalInfo"));
            $scope.thankYou.companyName = personalInfo.company;

            //Get contactable info
            let foundContactable = $scope.countryList.find(c => c.country == personalInfo.country);

            if (foundContactable) {
                $scope.thankYou.contactName = foundContactable.contactName;
                $scope.thankYou.contactEmail = foundContactable.contactEmail;
            }
        }).catch(() => {
            alert("Unable to get the list of country");
        })
    }
}